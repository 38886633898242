import React, { FunctionComponent, useCallback, useState } from 'react';
import { Container, SEO, Typography } from '../components';
import TextField from '@material-ui/core/TextField';
import MUITypography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { ObjectLiteral } from '../type';
// import { graphql } from 'gatsby';

type WordsPagePropsType = {
    data: any;
};

export const WordList = styled.div`
    margin-bottom: 3rem;
`;

export const Phrase = styled.div`
    color: #afafaf;
`;

export const Word = styled.span`
    color: #333;
`;

export const Comment = styled.span`
    &:before {
      content: '// ';
    }
    color: gray;
`;

export const Date = styled.span`
    color: #333;
    margin-right: 1rem;
`;

const WordsPage: FunctionComponent<WordsPagePropsType> = () => {

    return (
        <Container>
            <SEO
                title="Слово дня"
            />
            <Typography>Слово дня</Typography>
            <WordList>
                <Phrase><Date>02.08.20</Date> So, <Word>as a recap</Word>, the thing is awesome. <Comment>в качестве резюме</Comment></Phrase>
                <Phrase><Date>01.08.20</Date> For us, these are quite <Word>hefty</Word> numbers. <Comment>здоровенный</Comment></Phrase>
                <Phrase><Date>31.07.20</Date> You are driving through <Word>residential</Word> area. <Comment>жилой</Comment></Phrase>
            </WordList>
        </Container>
    );
};

// export const pageQuery = graphql`
//     query HomePageQuery {
//     }
// `;

export default WordsPage;
